import { template as template_5a494bb39e1742f59746a53627b2aa48 } from "@ember/template-compiler";
const FKControlMenuContainer = template_5a494bb39e1742f59746a53627b2aa48(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
