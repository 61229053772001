import { template as template_24f45f4b6e85447dbe4e19d9ceb3174b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_24f45f4b6e85447dbe4e19d9ceb3174b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
