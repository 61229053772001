import { template as template_6dbbff11634a4d1c933d15f058c84ed1 } from "@ember/template-compiler";
const FKLabel = template_6dbbff11634a4d1c933d15f058c84ed1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
