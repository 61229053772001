import { template as template_0c1127c0ae084404a01de29d2c7760ae } from "@ember/template-compiler";
const WelcomeHeader = template_0c1127c0ae084404a01de29d2c7760ae(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
